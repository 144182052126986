/* eslint-disable import/no-extraneous-dependencies */
import 'core-js/actual';
import 'regenerator-runtime/runtime';
import 'raf/polyfill'; // required by React 16
import React, { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import NextApp, { AppProps, AppContext } from 'next/app';
import { useRouter } from 'next/router';
import { ThemeProvider, Theme } from 'styled-components';
import { LocaleObject, setLocale } from 'yup';
import * as FullStory from '@fullstory/browser';
import moment from 'moment';
import { ConnectedRouter } from 'connected-next-router';
import { StaticRouter } from 'react-router-dom';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';
import theme from '@whitelabel/global-theme';
import { loadMessages } from '@whitelabel/xcover-www-frontend/src/helpers/locales';
import '@whitelabel/xcover-shared/scss/index.scss';
import '@whitelabel/xcover-shared/css/cookieYes.css';
import { storeUserGeoIpInfo } from '@whitelabel/xcover-shared/helpers/utils';
import { loadPolyfills, loadMomentLocale } from '@whitelabel/xcover-shared/helpers/polyfills';
import { detectCookies, detectLanguage, site, updateFontFamily, isSupportedBrowser } from '@whitelabel/helpers/site';
import languages from '@whitelabel/helpers/languages.json';
import { CURRENT_LOCALE, USER_CONTINENT, USER_COUNTRY } from '@whitelabel/helpers/constants';
import { yupMessages } from '@whitelabel/helpers/forms/messages';
import { isOnServer, loadCountries } from '@whitelabel/helpers/utils';
import { captureExceptionWithFullStory, removeInvalidHash } from '@whitelabel/nextjs-helpers/utils';
import GlobalXCoverStyle from '@whitelabel/xcover-shared/styled/GlobalXCoverStyle';
import ErrorPage from '@whitelabel/xcover-www-frontend/src/components/ErrorPage';
import UnsupportedBrowserPage from '@whitelabel/xcover-shared/components/UnsupportedBrowserPage';
import NoCookiesErrorPage from '@whitelabel/xcover-www-frontend/src/components/NoCookiesErrorPage';
import { checkSession } from '@whitelabel/xcover-www-frontend/src/actions';
import { PackageComponentsProvider } from '@whitelabel/component-library/src/context/PackageComponents';
import packageComponents from '@whitelabel/xcover-shared/helpers/packageComponents';
import { renderMessageWithID } from '@whitelabel/xcover-www-frontend/src/helpers/i18n';
import { configureCognito } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { useBWTag } from '@whitelabel/helpers/hooks';
import { useStore } from '../store';
import { IInitialStoreState } from '../helpers/types';
import { getRequestIDInit, pinoLog } from '../../logger';
import CookieModal from '../components/CookieModal';

export const { NODE_ENV } = process.env;

async function loadCommonCode(locale: string) {
  await loadPolyfills(locale);
  await loadMomentLocale(locale);
}

interface IAppProps extends AppProps {
  initialStoreState: IInitialStoreState;
}

function App({ Component, initialStoreState, pageProps }: IAppProps): JSX.Element {
  const store = useStore(initialStoreState);
  const router = useRouter();
  const [areCookiesEnabled, setAreCookiesEnabled] = useState(true);
  const [isBrowserSupported, setIsBrowserSupported] = useState(true);
  const [appTheme, setAppTheme] = useState<Theme>(theme);
  const locale = router.locale === 'default' ? site.locale : router.locale;

  const intl = useMemo(() => {
    const { bidi } = languages[locale as keyof typeof languages] ?? { bidi: false };
    const modifiedIntl = createIntl(
      {
        locale: locale as string,
        messages: initialStoreState.intl.messages,
        // @ts-ignore
        bidi,
      },
      createIntlCache(),
    );
    const MESSAGE_DISPLAY_MODE = process.env.REACT_APP_MESSAGE_DISPLAY_MODE;
    if (MESSAGE_DISPLAY_MODE && !isOnServer) renderMessageWithID(modifiedIntl);
    return modifiedIntl;
  }, [locale, initialStoreState.intl.messages]);

  useBWTag();

  useEffect(() => {
    async function bootstrapApp() {
      if (window.top && window.top !== window) {
        window.top.location = window.location;
        throw new Error('Website could not load properly due to clickjacking');
      }

      try {
        configureCognito();
        site.locale = locale!;
        site.countries = await loadCountries(locale);
        await loadCommonCode(locale!);
        setLocale(yupMessages as LocaleObject);

        sessionStorage.setItem(CURRENT_LOCALE, locale || '');
        if (locale === 'ar') {
          moment.updateLocale('ar', {
            weekdaysMin: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
            preparse: (string: string) => string,
            postformat: (string: string) => string,
          });
        }
        store.dispatch(checkSession());
      } catch (e) {
        captureExceptionWithFullStory(e as Error);
      }
    }
    async function init() {
      try {
        const supportedBrowser = isSupportedBrowser();
        setIsBrowserSupported(supportedBrowser);
        const cookiesEnabled = detectCookies(Sentry);
        if (cookiesEnabled && (!sessionStorage.getItem(USER_COUNTRY) || !sessionStorage.getItem(USER_CONTINENT))) {
          await storeUserGeoIpInfo();
        }
        const hasLanguage = detectLanguage(undefined, router);
        if (!hasLanguage) return;

        if (!window.isHeadless) {
          FullStory.init({ orgId: '34DW0', devMode: NODE_ENV === 'development' });
        }

        if (cookiesEnabled && isBrowserSupported && initialStoreState.intl.messages) {
          bootstrapApp();
        }
        setAreCookiesEnabled(cookiesEnabled);
      } catch (e) {
        captureExceptionWithFullStory(e as Error);
      }
    }

    init();
  }, [router, initialStoreState.intl.messages]);

  useEffect(() => {
    setAppTheme((prevTheme) => updateFontFamily(locale, prevTheme));
  }, [locale]);

  const renderPageContent = () => {
    const isValidHash = removeInvalidHash(router);
    if (!isValidHash) return <></>;
    if (!isBrowserSupported) return <UnsupportedBrowserPage />;
    if (!initialStoreState.intl.messages)
      return (
        <ErrorPage
          title="Fetch messages failed"
          button={{
            label: 'Reload',
            onClick: () => window.location.reload(),
          }}
        />
      );

    if (areCookiesEnabled)
      return (
        <>
          <Component {...pageProps} />
          <CookieModal />
        </>
      );

    return <NoCookiesErrorPage />;
  };

  return (
    // @ts-ignore tofix: to upgrade react-redux major version?
    <Provider store={store}>
      <RawIntlProvider value={intl}>
        <ThemeProvider theme={appTheme}>
          <ConnectedRouter>
            <StaticRouter>
              <GlobalXCoverStyle />
              <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
              </Head>
              <PackageComponentsProvider value={{ xcoverShared: packageComponents }}>
                {renderPageContent()}
              </PackageComponentsProvider>
            </StaticRouter>
          </ConnectedRouter>
        </ThemeProvider>
      </RawIntlProvider>
    </Provider>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  const { req, res } = appContext.ctx;
  pinoLog(req, res);
  const appProps = await NextApp.getInitialProps(appContext);
  let { locale = 'en' } = appContext.router;
  if (locale === 'default') {
    locale = site.locale;
  }
  await loadCommonCode(locale);
  const { bidi } = languages[locale as keyof typeof languages] || {};
  let messages;
  try {
    messages = await loadMessages(locale, getRequestIDInit(req) as RequestInit);
  } catch (err) {
    messages = null;

    if (res) {
      res.statusCode = 502;
    }
  }

  const initialStoreState = {
    intl: {
      locale,
      messages,
      bidi,
    },
  };

  return {
    ...appProps,
    initialStoreState,
  };
};
export default App;
